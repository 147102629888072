import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import moment from 'moment';

@Pipe({
  name: 'imageGroupBy',
  pure: false // Set to false to handle dynamic updates, but consider caching
})
export class ImageGroupByPipe implements PipeTransform {
  private lastInput: any[] = [];
  private lastProperty: string = '';
  private lastResult: any;

  transform(collection: any[], property: string): any {
    if (!collection || property !== this.lastProperty || collection !== this.lastInput) {
      collection = collection.filter(item => !item.isDeleted);
      this.lastProperty = property;
      this.lastInput = collection;
      this.lastResult = this.groupImages(collection, property);
    }
    return this.lastResult;
  }

  private groupImages(collection: any[], property: string): any {
    if (!collection) return null;
    let groupedCollection: any = [];
    if (property === 'createdDateTime') {
      groupedCollection = collection.reduce((previous, current) => {
        const currentCreatedDateTime = moment(current.createdDateTime).format('MM/DD/YYYY');
        previous[currentCreatedDateTime] = previous[currentCreatedDateTime] || [];
        previous[currentCreatedDateTime].push(current);
        return previous;
      }, {});
    } else {
      groupedCollection = _.orderBy(collection, [item => item.stage || ''], ['desc'])
        .reduce((previous, current) => {
          const key = current[property] === 'Live System' ? 'Live Site' : (current[property] || 'Images without Stage');
          previous[key] = previous[key] || [];
          previous[key].push(current);
          return previous;
        }, {});
    }
    return Object.keys(groupedCollection).map(key => ({
      key,
      value: groupedCollection[key],
      showImageSection: groupedCollection[key].length > 0,
      showDateSection: groupedCollection[key].length > 0
    }));
  }
}
